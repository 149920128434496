/* You can add global styles to this file, and also import other style files */
@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css';

/* Supreme Font */

body {
    overflow-x: hidden;
}


@font-face {
    font-family: 'Supreme-Regular';
    src: url('/assets/fonts/Supreme-Regular.woff2') format('woff2'),
        url('/assets/fonts/Supreme-Regular.woff') format('woff'),
        url('/assets/fonts/Supreme-Regular.ttf') format('truetype'),
        url('/assets/fonts/Supreme-Regular.eot');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Supreme-Medium';
    src: url('/assets/fonts/Supreme-Medium.woff2') format('woff2'),
        url('/assets/fonts/Supreme-Medium.woff') format('woff'),
        url('/assets/fonts/Supreme-Medium.ttf') format('truetype'),
        url('/assets/fonts/Supreme-Medium.eot');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Supreme-Bold';
    src: url('/assets/fonts/Supreme-Bold.woff2') format('woff2'),
        url('/assets/fonts/Supreme-Bold.woff') format('woff'),
        url('/assets/fonts/Supreme-Bold.ttf') format('truetype'),
        url('/assets/fonts/Supreme-Bold.eot');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Supreme-Extrabold';
    src: url('/assets/fonts/Supreme-Extrabold.woff2') format('woff2'),
        url('/assets/fonts/Supreme-Extrabold.woff') format('woff'),
        url('/assets/fonts/Supreme-Extrabold.ttf') format('truetype'),
        url('/assets/fonts/Supreme-Extrabold.eot');
    font-weight: 800;
    font-style: normal;
}


html,
body {
    font-family: "Supreme-Regular";
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    max-width: 100%;
    overflow: hidden;
}



@screen-mobile: ~"only screen and (max-width:767px)";
@screen-bigscreen: ~"only screen and (min-width:768px)";
@screen-tabSmall: ~"only screen and (min-device-width:768px) and (max-device-width:1023px) and (-webkit-min-device-pixel-ratio: 2)";
@screen-tab: ~"only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)";
@screen-laptop: ~"only screen and (max-width:1400px)";

.error-text {
    color: #dc3545;
    margin-top: 5px;
    font-size: 12px;
    display: block;
}

.logo_wrap h2 {
    font-weight: 700;
    font-size: 30px;
    padding-left: 15px;
    margin: 0 0px 0 0;
}

.row .box-right .sec_login {
    max-width: 360px;
    margin: 0 auto;
    margin-left: 95px;
    padding: 50px 0;
}

.input_wrap input:placeholder {
    color: #8a95a8;
    font-family: Supreme-Regular;
    font-size: 17px
}


.top_space {
    margin-top: 25px !important;
}

.input_wrap .input_icon-wrap input:focus-visible {
    outline: none;
}

.frm_head {
    font-family: Supreme-Bold;
    color: #060b14;
    margin: 0 0 15px;
    font-size: 24px;
}

.btn-dark {
    width: 100%;
    color: #fff;
    background-color: #e31b54;
    border: 1px solid #e31b54;
    border-radius: 2px;
    padding: 9px 35px;
    text-align: center;
    margin: 2px 0 0;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
}

/* .btn-dark:hover {
    color: inherit;
} */

form .btn-dark[disabled] {
    background-color: #e4e7ec;
    border: 1px solid #e4e7ec;
    color: #a4acba;
    cursor: default;
}

.inner-wrapper .otp-box input {
    width: 100%;
    color: #101828;
    border: 1px solid #e4e7ec;
    border-radius: 2px;
    text-align: center;
    font-size: 18px;
    min-width: 45px;
    min-height: 45px;
    width: 100%;
    caret-color: #e31b54;

}


.owl-dots .owl-dot {
    width: 8px;
    height: 5px;
    cursor: pointer;
    background: #8a95a8;
    border: 1px solid transparent;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
    transition: all .4s ease-in;
    vertical-align: middle;
    margin: 0 4px;
}


.owl-dots .owl-dot.active {
    width: 16px;
    height: 5px;
    background: #e31b54;
    transition: all .2s;
}



@media (max-width:767px) {

    .box-left {
        display: none;
    }

    .row .col-6.box-right {
        width: 100%;
        flex: 100%;
        padding: 0 15px 0px 15px;
    }

    .row .box-right .sec_login {
        max-width: 100%;
        margin: 0;
    }

    .login-5paisa {
        padding: 20px 0 !important;
    }
}

.login-mobile-view {
    margin-bottom: 25px;
}

@media(min-width:768px) {
    .login-mobile-view {
        display: none;
    }
}


.risk-disc .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
}

.declaration .mdc-form-field {
    align-items: start;
    margin-left: -10px;
}


@media(max-width:768px) {
    .logo_wrap h2 {
        font-size: 26px;
        padding: 0;
    }

    .logo_wrap img.logo-img {
        width: 115px !important;
        margin: 0;
    }

    .login-5paisa {
        padding: 28px 0 !important;
    }

    .login-mobile-view {
        margin-bottom: 42px;
    }

    .row .box-right .sec_login {
        padding: 0 15px;
    }


}

.hide{
    display: none;
  }

  span.link {
    color: #0d6efd;
    margin-top: 10px;
    display: block;
    font-size: 13px;
  }
button[disabled]{
    background-color: #CFD0D4;
    border: 0;
}